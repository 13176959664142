import React from "react";
import * as P from "../../partials/SectionForm/parts";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";
import {useI18next} from "gatsby-plugin-react-i18next";

export const enSolutions = ['Individuals','Fleet','Custom Solution'];
export const plSolutions = ['Dla osób indywidualnych','Dla floty','Rozwiązaniem dedykowanym'];

const Form = () => {
    const {language} = useI18next();
    const activeSolutions = language === 'pl' ? plSolutions: enSolutions;

    const contactFormSchema = Yup.object().shape({
        name: Yup.string().max(30, 'Max name length is 30 characters.').required('Name is required.'),
        subject: Yup.string().max(100, 'Max subject name length is 100 characters.').required('Subject is required.'),
        email: Yup.string().email('Invalid email.').max(50, 'Max email length is 50 characters.').required('Email is required.'),
        message: Yup.string().max(5000, 'Max message length is 5000 characters'),
        checkbox: Yup.boolean().oneOf([true], 'This field is required.'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            subject: '',
            email: '',
            solution: activeSolutions[0],
            message: '',
            checkbox: false,
        },
        validationSchema: contactFormSchema,
        onSubmit: () => {
            handleSubmitForm();
        },
    })

    function handleSubmitForm () {
        console.log(formik.values);
        formik.resetForm();
    }

    return (
        <P.StyledForm onSubmit={formik.handleSubmit}>
            <P.InputWrapper>
                <P.StyledLabel htmlFor={'name'}>
                    <Trans>contact_page_your_name</Trans>
                </P.StyledLabel>
                <P.StyledInput
                    id={'name'}
                    name={'name'}
                    type={'text'}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name ? <P.StyledError>{formik.errors.name}</P.StyledError> : null}
            </P.InputWrapper>
            <P.InputWrapper>
                <P.StyledLabel htmlFor={'subject'}>
                    <Trans>contact_page_subject</Trans>
                </P.StyledLabel>
            </P.InputWrapper>
            <P.StyledInput
                id={'subject'}
                name={'subject'}
                type={'text'}
                value={formik.values.subject}
                onChange={formik.handleChange}
            />
            {formik.errors.subject && formik.touched.subject ? <P.StyledError>{formik.errors.subject}</P.StyledError> : null}
            <P.InputWrapper>
                <P.StyledLabel htmlFor={'email'}>
                    <Trans>contact_page_your_email</Trans>
                </P.StyledLabel>
                <P.StyledInput
                    id={'email'}
                    name={'email'}
                    type={'email'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email ? <P.StyledError>{formik.errors.email}</P.StyledError> : null}
            </P.InputWrapper>
            <P.InputWrapper>
                <P.StyledLabel htmlFor={'solution'}>
                    <Trans>contact_page_what_are_you_interested</Trans>
                </P.StyledLabel>
                <P.StyledSelect
                    name={'solution'}
                    id={'solution'}
                    value={formik.values.solution}
                    onChange={formik.handleChange}
                >
                    {
                        activeSolutions.map((item, index) => {
                            return <option key={`${item}_${index}`} value={item}>{item}</option>
                        })
                    }
                </P.StyledSelect>
                <P.StyledSelectArrow>
                    <FontAwesomeIcon icon={faChevronDown} />
                </P.StyledSelectArrow>
            </P.InputWrapper>
            <P.InputWrapper>
                <P.StyledLabel>
                    <Trans>contact_page_your_message</Trans>
                </P.StyledLabel>
                <P.StyledTextarea
                    name={'message'}
                    id={'message'}
                    value={formik.values.message}
                    onChange={formik.handleChange}
                />
            </P.InputWrapper>
            <P.CheckboxWrapper>
                <P.StyledCheckbox
                    type={'checkbox'}
                    id={'checkbox'}
                    name={'checkbox'}
                    checked={formik.values.checkbox}
                    onChange={formik.handleChange}
                />
                <P.StyledCheckboxLabel htmlFor={'your_information'}>
                    <Trans>contact_page_your_information</Trans>
                </P.StyledCheckboxLabel>
                {formik.errors.checkbox && formik.touched.checkbox ? <P.StyledError>{formik.errors.checkbox}</P.StyledError> : null}
            </P.CheckboxWrapper>
            <P.SubmitButton type={'submit'}>
                <Trans>contact_page_request</Trans>
            </P.SubmitButton>
        </P.StyledForm>
    );
};

export default Form;