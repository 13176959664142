import React from "react";
import * as A from '../../../assets/images';
import {SectionForm} from "../../partials";
import Form from "../Form/Form";
import {useTranslation} from "react-i18next";

const SectionContact = () => {
    const {t} = useTranslation();

    return (
        <SectionForm
            title={t('contact_page_interested_in_mobd')}
            subtitle={t('contact_page_use_our_contact_form')}
            img={A.appsDemo}
            imgWidth={'90%'}
            altImg={'Apps demo'}
        >
            <Form />
        </SectionForm>
    );
}

export default SectionContact;