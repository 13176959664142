import * as React from "react"
import Page from "../components/Page";
import SEO from "../components/utils/SEO";
import {Copyright, Footer, Nav} from "../components/partials";
import {SectionContact} from "../components/contact";
import {graphql} from "gatsby";
import {useTranslation} from "react-i18next";

const ContactPage = () => {
    const {t} = useTranslation();

    return (
        <Page>
            <SEO title={t('page_title_contact')} />
            <Nav />
            <SectionContact />
            <Footer />
            <Copyright />
        </Page>
    )
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;